export default {
  DealerLocationsPopover: {
    default: {
      root: `
        .locations-header {
          display: grid;
          grid-template-columns: 52px;
          grid-gap: 8px;
          align-items: center;
          max-width: 220px;

          :hover {
            cursor: pointer;
          }

          .closest-dealership-phone-number {
            display: flex;
            align-items: center;
            color: var(--mainColor);
            padding: 10px 16px;
            background: #F4FCFA;
            border-radius: 12px;
            line-height: 24px;
            text-decoration: none;
            
            &:hover {
              background: #E9F9F5;
            }

            svg {
              margin-right: 0px;

              path {
                fill: var(--mainColor);
              }
            }
            
            span {
              color: inherit;
              display: none;
            }
          }
          
          &.active .collapsible-icon {
            transform: rotate(180deg);
          }
          
          @media (min-width: 1280px) {
            grid-template-columns: 173px;
            max-width: 220px;
            
            .closest-dealership-phone-number {
              padding: 8px 16px;
  
              svg {
                margin-right: 8px;
              }
  
              span {
                display: block;
              }
            }
          }
        }
      `,
      content: `
        background-color: #fff;
        border-radius: 12px;
        padding: 24px;
        font-family: Roboto, sans-serif;
        display: grid;
        grid-row-gap: 16px;

        .dealer-info-item {
          padding-bottom: 16px;
          border-bottom: 1px solid #0000001F;
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 8px;

          h6 {
            font-size: 20px;
            font-weight: 500;
          }

          .location, .phone {
            display: grid;
            grid-template-columns: auto;
            grid-gap: 8px;
            text-decoration: none;
            color: #212121CC;

            :hover {
              cursor: pointer;
            }

            svg path {
              fill: var(--mainColor);
            }
          }

          .phone span {
            font-weight: 500;
          }
        }
      `
    }
  }
}
