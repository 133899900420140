export default {
  VehicleBriefInfo: {
    default: {
      root: `
        border-radius: 12px;
        height: fit-content;
        background: white;
        top: 0;
        display: grid!important;
        row-gap: 16px;
        
        @media screen and (max-width: 767px) {
          margin-top: 16px;
        }
        
        .brief-info-name {
          font-weight: 700;
          color: #101010;
          font-size: 18px;
          
          span {
            color: inherit;
            font-weight: inherit;
            font-size: 16px;
          }
          
          @media screen and (min-width: 600px) {
            font-size: 24px;
            line-height: 28.8px;
            letter-spacing: -0.36px;
            
            span {
              font-size: 20px;
              line-height: 30px;
            }
          }
        }
        
        .brief-info-price-mileage-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .brief-info-sale-price {
            font-weight: 700;
            font-size: 20px;
            margin-right: 5px;
          }
          
          .brief-info-mileage {
            color: #101010;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.24px;
          }
        }
        
        .brief-info-vin-stock-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .payment-block-wrapper {
          padding: 10px;
          border-radius: 8px;
          background: #F4FCFA;
          
          .payment-block-title, .payment-block-info {
            display: flex;
            justify-content: space-between;
          }
          
          .payment-block-info {
            line-height: 24px;
          }
          
          .payment-block-title {
            margin-bottom: 8px;
            font-weight: 500;
          }
          
          .cash-down {
            color: rgba(33, 33, 33, 0.80);
          }
          
          .info-wrapper {
            position: relative;

            :hover > .payment-tooltip {
              display: block;
            }

            .payment-tooltip {
              :hover {
                display: block;
              }
              
              display: none;
              position: absolute;
              max-width: 266px;
              padding: 4px 12px;
              border-radius: 4px;
              background: #212121;
              top: 0;
              right: 0;
              color: white;
              transform: translate(0px, -100%);
              width: max-content;
              font-size: 14px;
            }
          }
        }
        
        .TooltipText {
          width: min-content;
        }
        
        @media (min-width: 1440px) {
          z-index: 1;
          position: sticky;
          margin-bottom: 40px
        }

        & .ExploreFinancingButton .MuiButton-root {
          height: 56px;
          box-sizing: border-box;
          border-radius: 12px;
          color: #fff;
        }

        .GetStartedButton {
          margin: 0;
          margin-top: 8px;
          button {
            width: 100%;
          }
        }
        
        .ask-button.cf-button-root {
          text-decoration: none;

          .cf-button-content {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            
            img {
              margin-right: 8px;
            }
          }
        }

        .ExploreFinancingButton {
          & > div > button.MuiButton-root {
            color: white;
            background: #355E7F;
            margin: 0;

            &:hover {
              background-color: #063D59;
            }
          }
        }
      `
    }
  }
}