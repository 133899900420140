import { css, cx } from '@emotion/css'
import { FlexTemplate } from 'website/components/types'

const _createLayoutTemplate = ({ name, props, fullOffset }: FlexTemplate): string => {
  const templateBase = `
    display: flex;
    flex-wrap: ${props?.flexWrap ?? 'wrap'};
    min-width: ${props?.minWidth ?? 'unset'};
    max-width: ${props?.maxWidth ?? 'unset'};
    align-content: ${props?.alignContent ?? 'unset'};
    ${props?.padding != null ? `padding: ${props.padding};` : ''}
    ${props?.margin != null ? `margin: ${props.margin};` : ''}
  `

  const rowGap: string = props?.rowGap ?? '0px'
  const colGap: string = props?.colGap ?? '0px'

  const gapStyles = `
    margin-left: calc(${rowGap} / ${fullOffset === true ? -1 : -2});
    margin-bottom: calc(${colGap} / ${fullOffset === true ? -1 : -2});

    & > * {
      margin-left: ${rowGap};
      margin-bottom: ${colGap};
    }
  `

  switch (name) {
    /**
     * this one is added to enable convenient wrapping of a supported component into a Dynamic component.
     * 4 divs is generated and full width and height is not passing
     * from the Dynamic inner div to the Component outer div without any additional style
     */
    case 'container': {
      return `
        ${templateBase}

        & > * {
          flex: 1;
        }
      `
    }
    case 'flex-full-row': {
      return `
        ${templateBase}
        ${gapStyles}
        justify-content: stretch;
        align-items: center;
      `
    }
    case 'flex-row-start': {
      return `
        ${templateBase}
        ${gapStyles}
        align-items: ${props?.alignItems ?? 'center'};
      `
    }
    case 'flex-row-start-nowrap': {
      return `
        ${templateBase}
        ${gapStyles}
        align-items: ${props?.alignItems ?? 'center'};
        flex-wrap: nowrap;
      `
    }
    case 'flex-row-between': {
      return `
        ${templateBase}
        ${gapStyles}
        justify-content: space-between;
        align-items: ${props?.alignItems ?? 'flex-start'};
      `
    }
    case 'flex-row-center': {
      return `
        ${templateBase}
        ${gapStyles}
        justify-content: center;
        align-items: ${props?.alignItems ?? 'center'};
      `
    }
    case 'flex-column': {
      return `
        ${templateBase}
        ${gapStyles}
        flex-direction: column;
        flex-wrap: nowrap;
      `
    }
    case 'flex-column-start': {
      return `
        ${templateBase}
        ${gapStyles}
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        align-content: flex-start;
      `
    }
    case 'flex-row-around': {
      return `
        ${templateBase}
        ${gapStyles}
        justify-content: space-around;
        
        & > * {
          flex-grow: auto;
          min-width: ${props?.sectionMinWidth ?? 'auto'};
        }
      `
    }
    /**
     * we may add layout-templates for each component specificly.
     * It even may contain all styles needed for the component for faster development
     * We also might store all templates in a separate folder
     */
    case 'default':
    default: {
      return ''
    }
  }
}

export const createLayoutTemplate = (template: FlexTemplate): string => {
  return cx(css(_createLayoutTemplate(template)))
}
