import type { Fields } from 'persona/dist/lib/interfaces'

export interface GetFilesPayload {
  filesIds: number[]
}

export interface FileAPI {
  id: number
  uri: string
  name: string
  fileType: FileType
}

export interface UploadFilesPayload{
  customerId?: number
  folderName: string
  file: File
  fileTypeId: FileType
}

export interface UploadedFile {
  fileId: string
  fileTypeId: FileType
}

export interface UploadDriverLicensePayload {
  inquiryId: string
}

export interface UploadFilesResponse {
  files: UploadedFile[]
}

export interface DriverLicenseUploadResponse extends UploadFilesResponse{
  verificationFailedReasons: string[]
}

export enum DriverLicenseUploadStatusType {
  Completed = 'Completed',
  Failed = 'Failed'
}

export interface Document {
  fileId: string
  fileTypeId: FileType
}

export interface AddDriverLicenseDocumentsPayload {
  documents: DriverLicenseDocument[]
}

export interface DriverLicenseDocument extends Document {
  driverLicenseUploadStatus: DriverLicenseUploadStatusType
  verificationFailedReasons?: string[]
}

export interface AddDocumentsPayload {
  workflowId: number | undefined
  isClientArea: boolean
  documents: Document[]
}

export interface DocumentDetails {
  id: number
  fileId: number
  fileTypeId: FileType
  fileName: string
  driverLicenseUploadStatus?: DriverLicenseUploadStatusType
}

export interface DocumentChipItem {
  id: number | string
  name: string
  uri?: string
}

export interface ReaderFile {
  id: number | string
  file: File
}

export enum DocumentsFolderName {
  DriverLicense = 'DriverLicense',
  Insurance = 'Insurance',
  Other = 'Other'
}

export enum FileType {
  DriverLicense = 'DriverLicense',
  Paperwork = 'Paperwork',
  Insurance = 'Insurance',
  Other = 'Other',
  NotarizedPaperwork = 'NotarizedPaperwork'
}

export interface SetupPersonaFlowPayload extends Fields {
  referenceId: string
}

export interface SetupPersonaFlowResponse {
  accountId: string
}

export interface CloudAccessToken {
  token: string
  expirationDate: string
}
