import { keys } from 'lodash-es'

export const CustomerAppBaseUrl = 'deal'

export enum RelativeRoutes {
  YourDetails = 'YourDetails',
  IndependentPaymentShare = 'payment-calculator',

  PersonalDetails = 'PersonalDetails',
  TradeIn = 'TradeIn',
  Financing = 'Financing',
  FinancingCalculator = 'Financing/calculator',
  FinancingCoapplicant = 'Financing/co-applicant',
  ServiceAndProtection = 'ServiceAndProtection',
  IndependentCoverage = 'IndependentServiceAndProtection',
  CreditApplication = 'CreditApplication',
  DealerCheck = 'DealerCheck',
  DealerCheckCompleted = 'DealerCheckCompleted',
  Documents = 'Documents',
  SignOnline = 'SignOnline',
  PickupAndDelivery = 'PickupAndDelivery',
  Payments = 'Payments',
  SplitPayment = 'Payments/split-payment',
  PaymentsInsurance = 'Payments/insurance',
  PaymentSummary = 'PaymentSummary',

  IndependentCoverageSummary = 'Summary',
  SuccessCoverageAdding = 'SuccessCoverageAdding',
  NoDealerAndVin = '404',
  Callback = 'Callback',
  ClientArea = 'ClientArea',
  MultipleDeals = 'MultipleDeals',
  Login = 'login',
  Signup = 'signup',
  SignupByInviteExpired = 'signup/invite/expired',
  Reset = 'reset',
  ResetPassword = 'reset/password',
  ResetSent = 'reset/sent',
  ReviewOrder = 'ReviewOrder'
}

export const getAbsolutePath = (path: string = ''): string => {
  const relativePath = path.replace(`/${CustomerAppBaseUrl}`, '')

  return `/${CustomerAppBaseUrl}/${relativePath}`
}

/**
 * Added RelativeRoutes mapper to create Routes object with '/deal'
 * prefix before every relative route from enum.
 * This approach allow us to avoid wrong relative redirect using navigation.
 * New navigate in react-router v6 (history.push in v5) allows going by relative level.
 * So this approach deliver us from relative nested navigation dependencies.
 * */

export const Routes = keys(RelativeRoutes).reduce((routes: Record<string, string>, key) => {
  routes[key] = getAbsolutePath(RelativeRoutes[key as keyof typeof RelativeRoutes])

  return routes
}, {})

export enum TradeInSubRoutes {
  Vin = 'vin',
  Mileage = 'mileage',
  Summary = 'summary',
  UnableEstimate = 'UnableEstimate'
}

export enum SignupSubRoutes {
  Invite = 'invite',
  Expired = 'expired'
}

export enum PaymentsSubRoutes {
  Insurance = 'insurance',
  SplitPayments = 'split-payment'
}

export enum FinancingSubRoutes {
  Calculator = 'calculator',
  CoApplicant = 'co-applicant'
}

export const SKIPPED_AUTH_ROUTES: string[] = [
  Routes.SuccessCoverageAdding,
  Routes.IndependentCoverage,
  Routes.YourDetails,
  Routes.IndependentPaymentShare
]
