import { FC, useEffect, useState, useRef } from 'react'
import { cnx, Loader } from '@carfluent/common'

import ExtendedTypography from 'website/components/_base/ExtendedTypography'

import { loadNonGMScript } from 'utils/loadScript'
import { type CustomerReviewsProps, SupportedComponents } from 'website/components/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'

import CLASS_NAME from './styles'

const DEALER_RATER_SCRIPT_URL = 'https://apps.elfsight.com/p/platform.js'
const INTERVAL_DELAY = 1000
const MAX_ITERATIONS = 3

// HARDCODE for choosing correct link for widjet of reviews
// All DEALER_RATE_IDS are in env for getting correct one,
// we need to add this hardcode mapping for multirooftop
enum DealerIds {
  ProjectOne = 1,
  DriveSimpleTexas = 3,
  DriveSimpleNewJersey = 4,
  A3AUTO = 7
}

const DEALER_IDS_MAP = {
  [DealerIds.ProjectOne]: 'PROJECTONE',
  [DealerIds.DriveSimpleTexas]: 'DRIVESIMPLE_TEXAS',
  [DealerIds.DriveSimpleNewJersey]: 'DRIVESIMPLE_NEWJERSEY',
  [DealerIds.A3AUTO]: 'A3AUTO'
}

const getDealerRateId = (
  dealershipId: DealerIds | null
): string | null | undefined => {
  if (dealershipId == null) {
    return null
  }

  return process.env[`REACT_APP_${DEALER_IDS_MAP[dealershipId]}_DEALER_RATER_ID`]
}

const useSharedState = SharedStateHook<Store.ReviewDealership>(StoreBranches.ReviewDealershipId)

const CustomerReviews: FC<CustomerReviewsProps> = ({ Text }) => {
  const firstLoadRef = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isNoReviews, setNoReviews] = useState(false)
  const [{ dealershipId }] = useSharedState(defaultInstance(StoreBranches.ReviewDealershipId))

  const dealerRateId = getDealerRateId(dealershipId)

  useEffect(() => {
    setIsLoading(true)
    setNoReviews(false)
    let interval: NodeJS.Timeout

    const runEffect = async (): Promise<void> => {
      if (dealerRateId != null) {
        if (!firstLoadRef.current) {
          await loadNonGMScript(DEALER_RATER_SCRIPT_URL)
          firstLoadRef.current = true
        }

        let iterations = 0
        interval = setInterval(() => {
          const parent = document.querySelector('.cf-customer-reviews')
          const isMaxIterations = iterations >= MAX_ITERATIONS
          const isElementInDOM = parent?.querySelector('.es-review-background-container') != null

          if (isElementInDOM || isMaxIterations) {
            setIsLoading(false)
            setNoReviews(!isElementInDOM)
            clearInterval(interval)
          }

          iterations++
        }, INTERVAL_DELAY)
      }
    }

    void runEffect()

    return () => {
      clearInterval(interval)
    }
  }, [dealerRateId])

  if (isNoReviews) {
    return null
  }

  return (
    <div className={SupportedComponents.CustomerReviews}>
      <div className={CLASS_NAME}>
        <ExtendedTypography {...Text.config} className='cf-customer-reviews-text'>
          {Text.value}
        </ExtendedTypography>
        <div className='cf-customer-reviews'>
          {dealershipId === DealerIds.ProjectOne && <div className={cnx(dealerRateId, isLoading && 'g-with-opacity')} />}
          {dealershipId === DealerIds.DriveSimpleTexas && <div className={cnx(dealerRateId, isLoading && 'g-with-opacity')} />}
          {dealershipId === DealerIds.DriveSimpleNewJersey && <div className={cnx(dealerRateId, isLoading && 'g-with-opacity')} />}
          {dealershipId === DealerIds.A3AUTO && <div className={cnx(dealerRateId, isLoading && 'g-with-opacity')} />}
          {
            isLoading && (
              <div className='g-loading-wrapper'>
                <Loader size='large' />
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default CustomerReviews
