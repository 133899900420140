import { useMemo } from 'react'
import { componentStylesFromTheme, createEmptyComponentStyles } from 'website/styles/utils'
import {
  SupportedComponentNames,
  SupportedComponents,
  ComponentVariant,
  SupportedStyledComponents,
  ComponentStylesFromTheme,
  VariantProps
} from 'website/components/types'
import { theme } from 'website/configs'

const isComponenNameSupportedForStyling = (name?: string): name is SupportedStyledComponents => {
  return name != null && name !== '' && (
    Object.values(SupportedComponentNames).includes(name as SupportedComponentNames) ||
    Object.values(SupportedComponents).includes(name as SupportedComponents)
  )
}

export const useComponentStyles = (
  componentName?: string,
  variant: ComponentVariant = 'default',
  variantProps: VariantProps = {}
): ComponentStylesFromTheme => {
  return useMemo(() => {
    return isComponenNameSupportedForStyling(componentName)
      ? componentStylesFromTheme(theme[componentName], variant, variantProps)
      : createEmptyComponentStyles()
  }, [componentName, variant, variantProps])
}
