export default (assetsMap: Record<string, string>, configJson: string): string => {
  Object.keys(assetsMap).forEach(key => {
    let placeholder = `{{${key}}}`

    if (key.includes('Template')) {
      placeholder = `"{{${key}}}"`
    }

    configJson = configJson.replace(new RegExp(placeholder, 'g'), assetsMap[key])
  })

  return configJson
}
