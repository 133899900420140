import { WrapperRequest } from './wrapper.api'

const BASE_URL = `${process.env.REACT_APP_CUSTOMERSCORE ?? ''}/api/v1/`
const ACTION_URL = 'static/documents/'
const FILES_URL = `${process.env.REACT_APP_FILES ?? ''}/api/v1/`
const HEADQUARTERS_ID = process.env.REACT_APP_HEADQUARTERS_ID ?? ''

class StaticDocumentsApi extends WrapperRequest {
  getTermsAndConditionsUrl (): string {
    return `${FILES_URL}Download/headquarters/${HEADQUARTERS_ID}/legal-terms-conditions`
  }

  getPrivacyPolicyUrl (): string {
    return `${BASE_URL}${ACTION_URL}PrivacyPolicy`
  }

  getArbitrationAgreementUrl (): string {
    return `${BASE_URL}${ACTION_URL}ArbitrationAgreement`
  }

  getDisclosureUrl (): string {
    return `${BASE_URL}${ACTION_URL}Disclosure`
  }
}

export const StaticDocumentsApiProvider = new StaticDocumentsApi()
export default StaticDocumentsApiProvider
