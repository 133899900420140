import tradeInPhone from 'website/assets/trade-in-phone.png'

import lowestPriceCarsBackground from 'website/assets/car-under-1.png'
import middlePriceCarsBackground from 'website/assets/car-under-2.png'
import highestPriceCarsBackground from 'website/assets/car-under-3.png'

const assets: Record<string, string> = {
  tradeInPhone,
  tradeInBlockText1: 'Trade/Sell your car',
  tradeInBlockText2: 'Get an appraisal of your car in a few minutes. Leave your details and we will find the best options for you.',

  lowestPriceCarsBackground,
  middlePriceCarsBackground,
  highestPriceCarsBackground,

  /** nested templates */
  /** -- they are replaced during parsing if proper order is fulfilled -- */
  aboutUsPromosForMainPageTemplate: '',
  dealerInfoTemplate: ''
}

export default assets
