import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import { isMainHeadquarters } from 'website/utils/isMultirooftop'

const _isMainMultirooftop = isMainHeadquarters()

// moved to hook because events for buttons will be added in close future
export const useMixPanelAnalytics = () => {
  const { pathname } = useLocation()
  const refMixPanelInitialized = useRef(false)

  useEffect(() => {
    if (!_isMainMultirooftop) {
      return
    }
    
    if (!refMixPanelInitialized.current) {
      refMixPanelInitialized.current = true
      mixpanel.init(
        process.env.REACT_APP_MIXPANEL_TOKEN ?? '',
        { debug: true, persistence: 'localStorage'}
      )
    }

    mixpanel.track_pageview()
  }, [pathname])
}
